import type { PropsWithChildren } from 'react'
import {
  TableCell
} from '@mui/material'

export function GainAndLossCell({ children, gainAndLoss }: PropsWithChildren<{ gainAndLoss: number }>) {
  return (
    <TableCell sx={{
      color: gainAndLoss >= 0 ? 'rgb(255 0 85)' : 'rgb(18 221 214)',
    }}>
      <span>{gainAndLoss >= 0 ? '+' : '-'}</span>
      {children}
    </TableCell>
  )
}
