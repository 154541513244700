import { useState, useEffect, type MouseEventHandler } from 'react'
import { useTheme } from '@mui/material/styles'
import {
  Stack,
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import { AddModal, type AddModalProps } from './AddModal'
import { EditModal, type EditModalProps } from './EditModal'
import { GainAndLossCell } from '../GainAndLossCell'
import { config } from '../../config'

type Data = {
  codes: {
    id: string
    code: string
    memo: string
  }[]
  currents: {
    code: string
    name: string
    industry: string
    price: string
    yieldRatio: string
    dividend: string
    dayBeforeValue: number
    dayBeforeRatio: number
    per: string
    pbr: string
  }[]
}

const fetchData = async () => {
  const res = await fetch(`${config.API}/api/track`, { mode: 'cors', credentials: 'include' })
  const data = await res.json()
  return data
}

export function Track() {
  const theme = useTheme()
  const [addModalProps, setAddModalProps] = useState<Omit<AddModalProps, 'handleClose' | 'handleSubmit'>>({ open: false })
  const [editModalProps, setEditModalProps] = useState<Omit<EditModalProps, 'handleClose' | 'handleSubmit'>>({ open: false, code: '', name: '', memo: ''})
  const [data, setData] = useState<Data | null>(null)

  useEffect(() => {
    fetchData().then((d) => setData(d))
  }, [])

  const onDelete = async (code: string) => {
    const res = await fetch(`${config.API}/api/track`, {
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({ code }),
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (res.ok) {
      const newCodes = data?.codes.filter((c) => c.code !== code)
      setData({ currents: data?.currents ?? [], codes: newCodes ?? [] })
    }
  }

  const onEdit = async (code: string, name: string | undefined, memo: string)=> {
    setEditModalProps({ open: true, code, name, memo })
  }

  return (
    <Box p={2}>
      <Paper sx={{ p: theme.spacing(2) }}>
        <Typography>監視銘柄</Typography>
        <TableContainer sx={{ mt: 1 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>code</TableCell>
                <TableCell>名前</TableCell>
                <TableCell>現在値</TableCell>
                <TableCell>配当利回り</TableCell>
                <TableCell>PER</TableCell>
                <TableCell>PBR</TableCell>
                <TableCell>1株配当</TableCell>
                <TableCell>前日比（%）</TableCell>
                <TableCell>前日比（円）</TableCell>
                <TableCell>業種</TableCell>
                <TableCell>memo</TableCell>
                <TableCell>リンク</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data?.codes ?? []).map((d) => {
                const e = data?.currents.find((c) => c.code === d.code)
                return (
                  <TableRow key={d.code}>
                    <Row
                      key={d.code}
                      code={d.code}
                      memo={d.memo}
                      elem={e}
                      onDelete={onDelete}
                      onEdit={onEdit}
                    />
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Button sx={{ mt: 2 }} variant="contained" onClick={() => setAddModalProps({ open: true })}>追加</Button>
      <AddModal
        {...addModalProps}
        handleClose={() => setAddModalProps({ open: false })}
        handleSubmit={async () => {
          await fetchData().then((d) => setData(d))
          setAddModalProps({ open: false })
        }}
      />
      <EditModal
        {...editModalProps}
        handleClose={() => setEditModalProps({ open: false, code: '', name: '', memo: '' })}
        handleSubmit={async () => {
          await fetchData().then((d) => setData(d))
          setEditModalProps({ open: false, code: '', name: '', memo: '' })
        }}
      />
    </Box>
  )
}

function Row(props: {
  code: string
  memo: string
  elem: Data['currents'][number] | undefined
  onDelete: (coode: string) => void
  onEdit: (code: string, name: string | undefined, memo: string) => void
}) {
  if (!props.elem) {
    return <></>
  }

  const onDelete: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    props.onDelete(props.code)
  }
  
  const onEdit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    props.onEdit(props.code, props.elem?.name, props.memo)
  }

  const { dayBeforeRatio, dayBeforeValue } = props.elem

  return (
    <>
      <TableCell>{props.code}</TableCell>
      <TableCell>{props.elem.name}</TableCell>
      <TableCell>{props.elem.price}円</TableCell>
      <TableCell>{props.elem.yieldRatio}%</TableCell>
      <TableCell>{props.elem.per}倍</TableCell>
      <TableCell>{props.elem.pbr}倍</TableCell>
      <TableCell>{props.elem.dividend}円</TableCell>
      <GainAndLossCell gainAndLoss={dayBeforeRatio}>{dayBeforeRatio}%</GainAndLossCell>
      <GainAndLossCell gainAndLoss={dayBeforeValue}>{dayBeforeValue}円</GainAndLossCell>
      <TableCell>{props.elem.industry}</TableCell>
      <TableCell>{props.memo}</TableCell>
      <TableCell>
        <Stack>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://finance.yahoo.co.jp/quote/${props.code}`}
          >Y!ファイナンス</a>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://irbank.net/${props.code}`}
          >IR BANK</a>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://kabutan.jp/stock/?code=${props.code}`}
          >
            かぶたん
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://minkabu.jp/stock/${props.code}/analysis`}
          >
            みんかぶ
          </a>
        </Stack>
      </TableCell>
      <TableCell>
        <IconButton aria-label="delete" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="edit" onClick={onEdit}>
          <ModeEditIcon />
        </IconButton>
      </TableCell>
    </>
  )
}