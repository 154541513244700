import type React from 'react'
import type { EChartsOption, ECharts } from 'echarts'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useEffect, useRef } from 'react'
import { init, getInstanceByDom } from 'echarts'

export type PieChartProps = {
  title: string;
  data: { name: string, value: number }[]
}

export default function PieChart(props: PieChartProps) {
  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let chart: ECharts;
    if (chartRef.current !== null) {
      chart = init(chartRef.current, 'light')
    }
    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener('resize', resizeChart)

    return () => {
      chart?.dispose()
      window.removeEventListener('resize', resizeChart)
    }
  }, [])
  
  useEffect(() => {
    const option: EChartsOption = {
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          data: props.data
        }
      ]
    }
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)
      chart?.setOption(option)
    }
  }, [props.data])

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="subtitle1">{props.title}</Typography>
      <div ref={chartRef} style={{ width: '100%', height: '100%', minHeight: '300px' }} />
    </Box>
  )
}
