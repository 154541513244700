import { useNavigate } from "react-router-dom"
import {
  Box,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon
} from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import SearchIcon from '@mui/icons-material/Search'
import AnalyticsIcon from '@mui/icons-material/Analytics'

export function NavMenu() {
  const navigate = useNavigate()

  return (
    <Box component="nav">
      <MenuList>
        <MenuItem onClick={() => navigate('/')}>
          <ListItemIcon>
            <StarIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>監視銘柄</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate('/portfolio')}>
          <ListItemIcon>
            <AnalyticsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>ポートフォリオ</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate('/search')}>
          <ListItemIcon>
            <SearchIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>検索</ListItemText>
        </MenuItem>
      </MenuList>
    </Box>
  )
}
