import { useState, type FormEventHandler, type ChangeEventHandler } from 'react'
import {
  Box,
  Stack,
  Paper,
  Typography,
  FormControl,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { config } from '../../config'

type Data = {
  code: string
  name: string
  industry: string
  price: string
  yieldRatio: string
  dividend: string
  dayBeforeRatio: string
  dayBeforeValue: string
  pbr: string
  per: string
}

export function Search() {
  const [code, setCode] = useState('')
  const [data, setData] = useState<Data | null>(null)
  const theme = useTheme()

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault()
    setCode(e.currentTarget.value)
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    if (!code) {
      return
    }
    const res = await fetch(`${config.API}/api/finance?code=${code}`, { mode: 'cors', credentials: 'include' })
    const d = await res.json()
    setData(d)
    setCode('')
  }

  return (
    <Box p={2}>
      <Paper sx={{ p: theme.spacing(2) }}>
        <Typography variant="h6" component="h2">
          検索
        </Typography>
        <form onSubmit={onSubmit} style={{ marginTop: '1em' }}>
          <FormControl>
            <Stack direction="row">
              <TextField
                label="証券コード"
                size="small"
                onChange={onChange}
              />
              <Button sx={{ ml: 1 }} variant="contained" type="submit">send</Button>
            </Stack>
          </FormControl>
        </form>
        <List>
          <ListItem>
            <ListItemText primary="証券コード" secondary={data?.code} />
          </ListItem>
          <ListItem>
            <ListItemText primary="名前" secondary={data?.name} />
          </ListItem>
          <ListItem>
            <ListItemText primary="業種" secondary={data?.industry} />
          </ListItem>
          <ListItem>
            <ListItemText primary="株価" secondary={`${data?.price ?? ''}円`} />
          </ListItem>
          <ListItem>
            <ListItemText primary="PER" secondary={`${data?.per ?? ''}倍`} />
          </ListItem>
          <ListItem>
            <ListItemText primary="PBR" secondary={`${data?.pbr ?? ''}倍`} />
          </ListItem>
          <ListItem>
            <ListItemText primary="配当" secondary={`${data?.yieldRatio ?? ''}%`} />
          </ListItem>
          <ListItem>
            <ListItemText primary="配当額" secondary={`${data?.dividend ?? ''}円`} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="JSON"
              secondary={<pre>{JSON.stringify(data, null, 2)}</pre>}
            />
          </ListItem>
        </List>
      </Paper>
    </Box>
  )
}

