import { Outlet } from "react-router-dom"
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Stack
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { NavMenu } from '../components/NavMenu'
import '../App.css'

export function RootLayout() {
  return (
    <>
      <Box sx={{ minHeight: '100%', minWidth: '100%' }}>
        <AppBar position="static">
          <Toolbar variant="dense">
          <Typography variant="h6" color="inherit" component="div">
            Portfolio Analyzer
          </Typography>
          </Toolbar>
        </AppBar>
        <main style={{ minHeight: '100%', display: 'grid', gridTemplateColumns: '180px 1fr' }}>
          <NavMenu />
          <Stack sx={{ background: grey[300], minWidth: '100%' }}>
            <Outlet />
          </Stack>
        </main>
      </Box>
    </>
  )
}
