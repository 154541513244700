import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'
import { RootLayout } from './RootLayout'
import { Track } from '../components/Track'
import { Search } from '../components/Search'
import { Portfolio } from '../components/Portfolio'

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: '/',
        element: <Track />
      },
      {
        path: '/portfolio',
        element: <Portfolio />
      },
      {
        path: '/search',
        element: <Search />
      }
    ]
  }
])

export function Router() {
  return (
    <RouterProvider router={router} />
  )
}