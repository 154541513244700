import { useState, type FormEventHandler, type ChangeEventHandler } from 'react'
import {
  Box,
  Stack,
  Divider,
  FormControl,
  TextField,
  Button,
  TextareaAutosize as BaseTextareaAutosize,
  Typography
} from '@mui/material'
import { styled } from '@mui/system'
import { blue, grey } from '@mui/material/colors'
import Modal, { type ModalOwnProps } from '@mui/material/Modal'
import { config } from '../../config'

export type AddModalProps = {
  open: boolean
  handleClose: ModalOwnProps['onClose']
  handleSubmit: () => Promise<void>
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

export function AddModal(props: AddModalProps) {
  const [code, setCode] = useState('')
  const [memo, setMemo] = useState('')

  const onChangeCode: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault()
    setCode(e.currentTarget.value)
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    if (!code) {
      return
    }
    await fetch(`${config.API}/api/track`, {
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({ code, memo }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    await props.handleSubmit()
    setCode('')
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          監視銘柄追加
        </Typography>
        <form onSubmit={onSubmit} style={{ marginTop: '1em' }}>
          <FormControl>
            <Stack>
              <TextField
                required={true}
                label="証券コード"
                size="small"
                onChange={onChangeCode}
              />
              <Textarea
                sx={{ mt: 1 }}
                minRows={3}
                placeholder="memo"
                onChange={(e) => {
                  e.preventDefault()
                  setMemo(e.currentTarget.value)
                }}
              />
            </Stack>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Button variant="outlined" type="submit">追加</Button>
          </FormControl>
        </form>
      </Box>
    </Modal>
  )
}

const Textarea = styled(BaseTextareaAutosize)(({ theme }) => `
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 4px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }
`);

