import {
  TableCell
} from '@mui/material'

export function PercentCell(props: { percent: number | null }) {
  const value = props.percent ? Math.ceil(props.percent * 100) / 100 : '-'
  return (
    <TableCell>{value}%</TableCell>
  )
}
