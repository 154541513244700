import { useState, useEffect, type MouseEventHandler } from 'react'
import {
  Box,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  IconButton
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import { useTheme } from '@mui/material/styles'
import { AddModal, type AddModalProps } from './AddModal'
import { EditModal, type EditModalProps } from './EditModal'
import { config } from '../../config'
import { GainAndLossCell } from '../GainAndLossCell'
import { PercentCell } from '../PercentCell'
import PieChart, { type PieChartProps } from '../PieChart/index'

type PortfolioType = {
  codes: string[]
  total: {
    取得額: number
    評価額: number
    配当: number
    損益: number
  }
  industry: {
    [key: string]: {
      評価額: number
      取得額: number
      損益: number
      配当: number
    }
  },
  results: {
    [key: string]: {
      code: string
      memo: string
      possession: number
      purchasePrice: number
      name: string
      price: string
      yieldRatio: string
      industry: string
      dividend: string
      per: string
      pbr: string
      評価額: number
      配当: number | null
      損益: number
    }
  }
}

const initPortfolio = (): PortfolioType => {
  return {
    codes: [],
    total: {
      取得額: 0,
      評価額: 0,
      配当: 0,
      損益: 0
    },
    industry: {},
    results: {}
  }
}

async function fetchData(options: { page: number }) {
  const page = options.page ?? 1
  const query = new URLSearchParams({ page: `${page}` })
  const res = await fetch(`${config.API}/api/portfolio?${query.toString()}`, { mode: 'cors', credentials: 'include' })
  const data = await res.json()
  if (!res.ok) {
    throw new Error('Failed to fetch data')
  }
  return data as {
    portfolio: {
      codes: string[]
      results: {
        [key: string]: {
          code: string
          memo: string
          possession: number
          purchasePrice: number
          name: string
          price: string
          yieldRatio: string
          industry: string
          dividend: string
          per: string
          pbr: string
        }
      }
    },
    hasNext: boolean
  }
}

async function fetchAllData() {
  let offset = 0
  const responses: Awaited<ReturnType<typeof fetchData>> = {
    portfolio: {
      codes: [],
      results: {}
    },
    hasNext: false
  }
  do {
    const res = await fetchData({ page: offset })
    responses.portfolio = {
      codes: [
        ...responses.portfolio.codes,
        ...res.portfolio.codes
      ],
      results: {
        ...responses.portfolio.results,
        ...res.portfolio.results
      }
    }
    offset++
    if (!res.hasNext || offset >= 10) {
      break
    }
  } while (true)
  return responses
}

function convertData(data: Awaited<ReturnType<typeof fetchAllData>>): PortfolioType {
  const init = initPortfolio()
  const total: PortfolioType['total'] = init.total
  const industry: PortfolioType['industry'] = {}
  const results: PortfolioType['results'] = {}

  for (const [code, value] of Object.entries(data.portfolio.results)) {
    const 取得額 = value.purchasePrice
    const 一株配当 = isNaN(Number(value.dividend)) ? null : Number(value.dividend)
    const 配当 = 一株配当 ? 一株配当 * value.possession : null
    const 評価額 = value.possession * Number(value.price.replace(',', ''))
    const 損益 = 評価額 - value.purchasePrice

    total.取得額 += 取得額
    total.評価額 += 評価額
    total.配当 += 配当 ?? 0
    total.損益 += 損益

    if (!industry[value.industry]) {
      industry[value.industry] = {
        評価額: 0,
        取得額: 0,
        損益: 0,
        配当: 0
      }
    }

    industry[value.industry].評価額 += 評価額
    industry[value.industry].取得額 += 取得額
    industry[value.industry].損益 += 損益
    industry[value.industry].配当 += 配当 ?? 0

    results[code] = {
      ...value,
      評価額,
      配当,
      損益
    }
  }

  return {
    codes: data.portfolio.codes,
    total,
    industry,
    results
  }
}

export function Portfolio() {
  const theme = useTheme()
  const [data, setData] = useState<PortfolioType>(initPortfolio())
  const [addModalProps, setAddModalProps] = useState<Omit<AddModalProps, 'handleClose' | 'handleSubmit'>>({ open: false })
  const [editModalProps, setEditModalProps] = useState<Omit<EditModalProps, 'handleClose' | 'handleSubmit'>>({
    open: false,
    code: '',
    name: '',
    memo: '',
    possession: 100,
    purchasePrice: 0,
  })

  useEffect(() => {
    fetchAllData().then((d) => {
      setData(convertData(d))
    })
  }, [])

  const onDelete: RowType['onDelete'] = async (code) => {
    const res = await fetch(`${config.API}/api/portfolio/${code}`, {
      mode: 'cors',
      credentials: 'include',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (res.ok) {
      const newCodes = data?.codes.filter((c) => c !== code)
      setData({
        ...data,
        codes: newCodes
      })
    }
  }

  const onEdit: RowType['onEdit'] = async (
    code,
    name,
    memo,
    possession,
    purchasePrice,
  ) => {
    setEditModalProps({
      open: true,
      code,
      name,
      memo,
      possession,
      purchasePrice,
    })
  }

  return (
    <>
      <Box p={theme.spacing(2)}>
        <Typography variant="h6" component="h2">
          ポートフォリオ
        </Typography>
        <Paper sx={{ p: theme.spacing(2), mt: 2 }}>
          <Total portfolio={data} />
        </Paper>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Paper sx={{ p: theme.spacing(2), flex: 1 }}>
            <Industry portfolio={data} />
          </Paper>
          <Stack spacing={2} sx={{ flex: 1 }}>
            <Paper sx={{ p: theme.spacing(2), flex: 1 }}>
              <YieldChart portfolio={data} />
            </Paper>
            <Paper sx={{ p: theme.spacing(2), flex: 1 }}>
              <PriceChart portfolio={data} />
            </Paper>
          </Stack>
        </Stack>
        <Paper sx={{ mt: 2, p: theme.spacing(2) }}>
          <AllTable portfolio={data} onDelete={onDelete} onEdit={onEdit} />
        </Paper>
        <Button sx={{ mt: 2 }} variant="contained" onClick={() => setAddModalProps({ open: true })}>追加</Button>
      </Box>
      { addModalProps.open && <AddModal
        {...addModalProps}
        handleClose={() => setAddModalProps({ open: false })}
        handleSubmit={async () => {
          await fetchAllData().then((d) => setData(convertData(d)))
          setAddModalProps({ open: false })
        }}
      /> }
      { editModalProps.open && <EditModal
        {...editModalProps}
        handleClose={() => setEditModalProps((current) => {
          return { ...current, open: false }
        })}
        handleSubmit={async () => {
          await fetchAllData().then((d) => setData(convertData(d)))
          setEditModalProps((current) => {
            return { ...current, open: false }
          })
        }}
      /> }
    </>
  )
}

function Total(props: { portfolio: PortfolioType }) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>取得合計</TableCell>
            <TableCell>評価合計</TableCell>
            <TableCell>受取配当金合計</TableCell>
            <TableCell>損益合計</TableCell>
            <TableCell>利回り</TableCell>
            <TableCell>簿価利回り</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{props.portfolio.total.取得額.toLocaleString()}円</TableCell>
            <TableCell>{props.portfolio.total.評価額.toLocaleString()}円</TableCell>
            <TableCell>{props.portfolio.total.配当.toLocaleString()}円</TableCell>
            <GainAndLossCell
              gainAndLoss={props.portfolio.total.損益}
            >{props.portfolio.total.損益.toLocaleString()}円</GainAndLossCell>
            <PercentCell percent={props.portfolio.total.配当 / props.portfolio.total.評価額 * 100} />
            <PercentCell percent={props.portfolio.total.配当 / props.portfolio.total.取得額 * 100} />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function Industry(props: { portfolio: PortfolioType }) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>業種</TableCell>
            <TableCell>取得額</TableCell>
            <TableCell>評価額</TableCell>
            <TableCell>配当</TableCell>
            <TableCell>損益</TableCell>
            <TableCell>配当利回り</TableCell>
            <TableCell>簿価利回り</TableCell>
            <TableCell>時価構成比</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(props.portfolio.industry).map(([key, val], i) => {
            return (
              <TableRow key={key}>
                <TableCell>{i}</TableCell>
                <TableCell>{key}</TableCell>
                <TableCell>{val.取得額.toLocaleString()}円</TableCell>
                <TableCell>{val.評価額.toLocaleString()}円</TableCell>
                <TableCell>{val.配当.toLocaleString()}円</TableCell>
                <GainAndLossCell gainAndLoss={val.損益}>{val.損益.toLocaleString()}円</GainAndLossCell>
                <PercentCell percent={val.配当 / val.評価額 * 100} />
                <PercentCell percent={val.配当 / val.取得額 * 100} />
                <PercentCell percent={val.評価額 / props.portfolio.total.評価額 * 100} />
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function YieldChart(props: { portfolio: PortfolioType }) {
  const data: PieChartProps['data'] = Object
    .entries(props.portfolio.industry)
    .map(([key, value]) => {
      return { name: key, value: Math.ceil((value.配当 / props.portfolio.total.配当 * 100) * 100) / 100 }
    })

  return (
    <PieChart title="配当金構成比" data={data} />
  )
}

function PriceChart(props: { portfolio: PortfolioType }) {
  const data: PieChartProps['data'] = Object
    .entries(props.portfolio.industry)
    .map(([key, value]) => {
      return { name: key, value: Math.ceil((value.評価額 / props.portfolio.total.評価額 * 100) * 100) / 100 }
    })

  return (
    <PieChart title="評価額構成比" data={data} />
  )
}

function AllTable(props: { portfolio: PortfolioType, onDelete: RowType['onDelete'], onEdit: RowType['onEdit'] }) {
  const { codes, results, total } = props.portfolio

  return (
    <TableContainer sx={{ mt: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>コード</TableCell>
            <TableCell>名称</TableCell>
            <TableCell>業種</TableCell>
            <TableCell>保有数</TableCell>
            <TableCell>取得額</TableCell>
            <TableCell>評価額</TableCell>
            <TableCell>損益</TableCell>
            <TableCell>現在株価</TableCell>
            <TableCell>PER</TableCell>
            <TableCell>PBR</TableCell>
            <TableCell>1株配当</TableCell>
            <TableCell>受取配当金</TableCell>
            <TableCell>配当利回り</TableCell>
            <TableCell>簿価利回り</TableCell>
            <TableCell>時価構成比</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(codes ?? []).map((code, i) => {
            const row = results[code]
            return (
              <TableRow key={code}>
                <Row
                  index={i + 1}
                  total={total}
                  elem={row}
                  onDelete={props.onDelete}
                  onEdit={props.onEdit}
                />
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type RowType = {
  index: number
  total: PortfolioType['total']
  elem: PortfolioType['results'][string]
  onDelete: (code: string) => Promise<void>
  onEdit: (
    code: string,
    name: string,
    memo: string,
    possession: number,
    purchasePrice: number,
  ) => Promise<void>
}

function Row(props: RowType) {
  const 配当 = props.elem.配当 ? props.elem.配当 : null
  const 受取配当金 = 配当 ? 配当 * props.elem.possession : null
  const 配当利回り = isNaN(Number(props.elem.yieldRatio)) ? null : Number(props.elem.yieldRatio)
  const 簿価利回り = 配当 ? 配当 / props.elem.purchasePrice * 100 : null
  const 時価構成比 = props.elem.評価額 / props.total.評価額 * 100

  const onDelete: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    props.onDelete(props.elem.code)
  }

  const onEdit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    props.onEdit(props.elem.code, props.elem?.name, props.elem.memo, props.elem.possession, props.elem.purchasePrice)
  }
  
  return (
    <>
      <TableCell>{props.index}</TableCell>
      <TableCell>{props.elem.code}</TableCell>
      <TableCell>{props.elem.name}</TableCell>
      <TableCell>{props.elem.industry}</TableCell>
      <TableCell>{props.elem.possession}株</TableCell>
      <TableCell>{props.elem.purchasePrice.toLocaleString()}円</TableCell>
      <TableCell>{props.elem.評価額.toLocaleString()}円</TableCell>
      <GainAndLossCell gainAndLoss={props.elem.損益}>{props.elem.損益.toLocaleString()}円</GainAndLossCell>
      <TableCell>{props.elem.price}円</TableCell>
      <TableCell>{props.elem.per}倍</TableCell>
      <TableCell>{props.elem.pbr}倍</TableCell>
      <TableCell>{配当 ? 配当.toLocaleString() : '-'}円</TableCell>
      <TableCell>{受取配当金? 受取配当金.toLocaleString() : '-'}円</TableCell>
      <PercentCell percent={配当利回り} />
      <PercentCell percent={簿価利回り} />
      <PercentCell percent={時価構成比} />
      <TableCell>
        <Stack>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://finance.yahoo.co.jp/quote/${props.elem.code}`}
          >Y!ファイナンス</a>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://irbank.net/${props.elem.code}`}
          >IR BANK</a>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://kabutan.jp/stock/?code=${props.elem.code}`}
          >
            かぶたん
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://minkabu.jp/stock/${props.elem.code}/analysis`}
          >
            みんかぶ
          </a>
        </Stack>
      </TableCell>
      <TableCell>
        <IconButton aria-label="delete" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="edit" onClick={onEdit}>
          <ModeEditIcon />
        </IconButton>
      </TableCell>
    </>
  )
}
