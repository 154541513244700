import { useState, type FormEventHandler } from 'react'
import {
  Box,
  Stack,
  Divider,
  FormControl,
  Button,
  TextareaAutosize as BaseTextareaAutosize,
  Typography
} from '@mui/material'
import { styled } from '@mui/system'
import { blue, grey } from '@mui/material/colors'
import Modal from '@mui/material/Modal'
import { config } from '../../config'

export type EditModalProps = {
  open: boolean
  code: string
  name: string | undefined
  memo: string
  handleClose: () => void
  handleSubmit: () => Promise<void>
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

export function EditModal(props: EditModalProps) {
  const [memo, setMemo] = useState('')

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    await fetch(`${config.API}/api/track/${props.code}`, {
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({ memo }),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    await props.handleSubmit()
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          銘柄修正 ({props.name})
        </Typography>
        <form onSubmit={onSubmit} style={{ marginTop: '1em' }}>
          <FormControl>
            <Stack>
              <Textarea
                sx={{ mt: 1 }}
                minRows={3}
                placeholder="memo"
                defaultValue={props.memo}
                onChange={(e) => {
                  e.preventDefault()
                  setMemo(e.currentTarget.value)
                }}
              />
            </Stack>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Stack flexDirection="row" justifyContent="flex-end">
              <Button variant="outlined" onClick={() => props.handleClose()}>キャンセル</Button>
              <Button variant="contained" sx={{ ml: 1 }} type="submit">編集</Button>
            </Stack>
          </FormControl>
        </form>
      </Box>
    </Modal>
  )
}

const Textarea = styled(BaseTextareaAutosize)(({ theme }) => `
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 4px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }
`);

